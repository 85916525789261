<template>
    <div>Autenticando...</div>
  </template>
  
  <script>
  export default {
    async created() {
      const urlParams = new URLSearchParams(window.location.search);
      const name = urlParams.get('name');
      const email = urlParams.get('email');
        console.log(email)
      if (name && email) {
        await this.authenticateUser(name, email);
      } else {
        console.error('Name and email parameters are required.');
      }
    },
    methods: {
      async authenticateUser(name, email) {
        try {
          const response = await fetch('https://beta.back.node.clubebonecar.com.br/api/chat-auth/create-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email })
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          localStorage.setItem('chatToken', data.token);
          this.$router.push('/chat');
        } catch (error) {
          console.error('Failed to authenticate user:', error);
        }
      }
    }
  };
  </script>
  