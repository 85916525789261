<template>
  <div id="chat-container" class="container mt-0 style-scope ytd-watch-flexy">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div id="chat" class="card style-scope ytd-watch-flexy">
          <div class="card-body custom-card-body">
            <div class="messages" id="messages">
              <div v-for="msg in messages" :key="msg.id" class="message p-2 mb-2 bg-light rounded">
                <strong>{{ msg.ChatUser.name }}:</strong> <span v-html="msg.message"></span>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="input-group">
              <button @click="toggleEmojiPicker" class="btn btn-light" :disabled="!isConnected">
                😀
              </button>
              <button @click="toggleStickerPicker" class="btn btn-light" :disabled="!isConnected">
                🖼️
              </button>
              <input v-model="newMessage" @keyup.enter="sendMessage" type="text" class="form-control custom-input"
                :disabled="!isConnected" placeholder="Envie sua mensagem aqui" :maxlength="maxMessageLength" />
              <button @click="sendMessage" class="btn btn-primary chat-header" :disabled="!isConnected">
                Enviar
              </button>
            </div>
            <emoji-picker v-if="showEmojiPicker" @emoji-click="addEmoji" class="emoji-picker-up"></emoji-picker>
            <StickerPicker v-if="showStickerPicker" @sticker-selected="sendSticker" class="sticker-picker">
            </StickerPicker>
            <div v-if="!isConnected" class="text-danger mt-2">Conexão com o chat perdida. Tentando reconectar...</div>
            <div v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'emoji-picker-element';
import StickerPicker from '../components/StickerPicker.vue'; // Caminho corrigido

export default {
  components: {
    StickerPicker
  },
  data() {
    return {
      messages: [],
      newMessage: '',
      ws: null,
      currentUser: {
        id: 1, // Defina isso corretamente. Aqui é apenas um exemplo
        name: 'Username'
      },
      isConnected: false, // Estado da conexão
      reconnectInterval: 5000, // Tempo de espera antes de tentar reconectar (em milissegundos)
      showEmojiPicker: false, // Estado para mostrar ou esconder o seletor de emojis
      showStickerPicker: false, // Estado para mostrar ou esconder o seletor de figurinhas
      maxMessageLength: 75, // Tamanho máximo da mensagem
      errorMessage: '' // Mensagem de erro para validação
    };
  },
  created() {
    this.connectWebSocket();
  },
  methods: {
    connectWebSocket() {
      const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      const wsUrl = `${wsProtocol}beta.back.node.clubebonecar.com.br`;
      this.ws = new WebSocket(wsUrl);

      this.ws.onmessage = (event) => {
        try {
          const messageData = JSON.parse(event.data);
          this.messages.push(messageData);
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } catch (error) {
          console.error('Error parsing message data', error);
        }
      };

      this.ws.onopen = () => {
        console.log('WebSocket connection established');
        this.isConnected = true;
        this.scrollToBottom();
      };

      this.ws.onclose = () => {
        console.log('WebSocket connection closed');
        this.isConnected = false;
        setTimeout(() => {
          this.connectWebSocket();
        }, this.reconnectInterval);
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        this.ws.close();
      };
    },
    sendMessage() {
      if (this.newMessage.trim() !== '' && this.currentUser && this.currentUser.id) {
        if (this.newMessage.length > this.maxMessageLength) {
          this.errorMessage = `A mensagem não pode ter mais de ${this.maxMessageLength} caracteres.`;
          return;
        }
        this.errorMessage = ''; // Limpa a mensagem de erro se a validação passar

        const jwtToken = localStorage.getItem('chatToken');
        const message = {
          message: this.newMessage,
          userId: this.currentUser.id,
          token: jwtToken
        };
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(message));
          this.newMessage = '';
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } else {
          console.error('WebSocket is not open');
        }
      } else {
        console.error('Current user is not defined or message is empty');
      }
    },
    sendSticker(sticker) {
      const jwtToken = localStorage.getItem('chatToken');
      const message = {
        message: `<img src="${sticker.url}" alt="${sticker.name}" class="chat-sticker" width="100" />`,
        userId: this.currentUser.id,
        token: jwtToken
      };
      if (this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify(message));
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } else {
        console.error('WebSocket is not open');
      }
      this.showStickerPicker = false;
    },
    scrollToBottom() {
      const messagesContainer = this.$el.querySelector('#messages');
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
      if (this.showEmojiPicker) {
        window.addEventListener('click', this.closeEmojiPicker);
      }
    },
    toggleStickerPicker() {
      this.showStickerPicker = !this.showStickerPicker;
    },
    closeEmojiPicker(event) {
      if (!this.$el.contains(event.target)) {
        this.showEmojiPicker = false;
        window.removeEventListener('click', this.closeEmojiPicker);
      }
    },
    addEmoji(event) {
      if (this.newMessage.length + event.detail.unicode.length <= this.maxMessageLength) {
        this.newMessage += event.detail.unicode;
      }
      this.showEmojiPicker = false;
    }
  },
  beforeUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  }
};
</script>

<style scoped>
@media (min-width: 768px) {
  .custom-card-body {
    background-color: #fbd7d7;
    min-height: 430px;
    height: 100%;
  }
}

@media (max-width: 767.98px) {
  .custom-card-body {
    background-color: #fbd7d7;
    min-height: 260px;
    height: auto;
  }
}

/* Define o estilo padrão do input */
input.custom-input {
  border-color: #CCCCCC;
  outline: none;
}

/* Define o estilo do input quando está em foco */
input.custom-input:focus {
  border-color: #AA677F;
  outline: none;
}

/* Define o estilo padrão do botão */
button.btn-primary.chat-header {
  background-color: #AA677F !important;
  border-color: #AA677F !important;
  color: #fff !important;
}

/* Define o estilo ao passar o mouse */
button.btn-primary.chat-header:hover {
  background-color: #AA677F !important;
  border-color: #AA677F !important;
  color: #fff !important;
}

/* Define o estilo ao clicar */
button.btn-primary.chat-header:active {
  background-color: #AA677F !important;
  border-color: #AA677F !important;
  color: #fff !important;
}

#chat-container {
  margin-top: 0px;
}

#chat {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

#messages {
  max-height: 400px;
  overflow-y: auto;
}

.message {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.card-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sticker-picker {
  position: absolute;
  bottom: 60px;
  left: 20px;
  z-index: 1000;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.emoji-picker-up {
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: 1000;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.sticker {
  cursor: pointer;
}

.sticker img {
  width: 50px;
  height: 50px;
}

.chat-sticker {
  width: 100px;
}
</style>
