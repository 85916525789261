<template>
    <div class="sticker-picker">
      <div v-for="sticker in stickers" :key="sticker.id" class="sticker" @click="selectSticker(sticker)">
        <img :src="sticker.url" :alt="sticker.name" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        stickers: [
          { id: 1, url: '../img/Euquero.png', name: 'Euquero' },
          { id: 2, url: '../img/Amei2.png', name: 'Amei' },
          { id: 3, url: '../img/bonequeira2.png', name: 'bonequeira' },
          { id: 4, url: '../img/frrase2.png', name: 'frrase2' },
          { id: 5, url: '../img/horabonecar2.png', name: 'horabonecar2' },
          { id: 6, url: '../img/logoBonecar2.png', name: 'logoBonecar2' },
          { id: 7, url: '../img/muitoamor2.png', name: 'muitoamor2' },
          { id: 7, url: '../img/meninacostura_2.png', name: 'Menina Costureira' },

          // Adicione mais figurinhas conforme necessário
        ]
      };
    },
    methods: {
      selectSticker(sticker) {
        this.$emit('sticker-selected', sticker);
      }
    }
  };
  </script>
  
  <style scoped>
  .sticker-picker {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .sticker {
    cursor: pointer;
  }
  .sticker img {
    width: 50px;
    height: 50px;
  }
  img.chat-sticker {
  width: 100px;
}
  </style>
  