import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Importar Bootstrap e seu CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// Importar o arquivo CSS global
import './assets/styles.css';
console.log('API URL:', process.env.VUE_APP_API_URL);
console.log('WS URL:', process.env.VUE_APP_WS_URL);
createApp(App).use(router).mount('#app');
